import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

const About = () => {
  return (
    <Layout>
      <SEO
        title="About Ruairidh"
        description="Ruairidh Wynne-McHardy's engineering profile"
      />
      <section className="text-gray-400 body-font bg-gray-900 h-auto lg:pt-14 sm:pt-4">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-4">
            <div className="lg:w-1/1 w-full mb-6 lg:mb-0">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto lg:text-center">
                About Ruairidh
              </h2>
              <p className="prose text-light text-xl text-gray-400 leading-relaxed mt-4">
                I'm a senior software engineer at
                <Link
                  className="text-white"
                  to="https://askporter.co"
                  href="https://askporter.co"
                >
                  &nbsp;AskPorter&nbsp;
                </Link>
                - a Google backed AI startup. I also wrote the popular{" "}
                <Link
                  className="text-white"
                  to="https://www.educative.io/courses/up-and-running-with-node-and-graphql"
                  href="https://www.educative.io/courses/up-and-running-with-node-and-graphql"
                >
                  Up and Running with Node and GraphQL course
                </Link>{" "}
                on Educative.
              </p>

              <p className="prose text-light text-xl text-gray-400 leading-relaxed mt-4">
                I'm currently based in Glasgow, Scotland and work remotely. In
                my free time, I like to exercise, read, make classic cocktails,
                and I'm learning Mandarin.
              </p>

              <p className="prose text-light text-xl text-gray-400 leading-relaxed mt-4">
                I work across the stack. I don't mind which languages I work
                with, but most recently I've been using TypeScript. You can find
                out more about my career on my{" "}
                <Link className="text-white" to="/cv" href="/cv">
                  &nbsp;CV
                </Link>
                . I'm also happy to connect with you on{" "}
                <Link
                  className="text-white"
                  to="https://www.linkedin.com/in/ruairidhwynnemchardy/"
                  href="https://www.linkedin.com/in/ruairidhwynnemchardy/"
                >
                  &nbsp;LinkedIn&nbsp;
                </Link>
                or
                <Link
                  className="text-white"
                  to="https://twitter.com/RuairidhWM"
                  href="https://twitter.com/RuairidhWM"
                >
                  &nbsp;Twitter
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About
